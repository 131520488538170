import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import UseCase, { UseCaseProps } from './templates/use-case';

const Rewards: React.FC = () => {
  const { heroImg, step1, step2, step3, step4 } = useStaticQuery(assetsQuery);

  const benefitsData = [
    {
      title: 'Remove friction at checkout',
      description:
        'Apply rewards and notify customers of points earned in real-time without additional steps at checkout.',
    },
    {
      title: 'Improve data capture',
      description: (
        <>
          Spot transactions made with any enrolled Visa<sup>©</sup>, Mastercard
          <sup>©</sup> or American Express<sup>©</sup> card.
        </>
      ),
    },
    {
      title: 'Create personalised customers experiences',
      description:
        'Build engaging and dynamic user experiences with granular real-time payment data.',
    },
    {
      title: 'Build holistic customer insights',
      description:
        'Better understand customers with an aggregated view of in-store and online payment data to enable holistic insights and reporting.',
    },
  ];

  const props: UseCaseProps = {
    title: 'Rewards',
    textAboveFold:
      'Fidel API’s Select Transactions API provide a frictionless way for customers to earn instant incentives and rewards by connecting payment cards to programmes or apps.',
    datasheetUrl: 'Rewards.pdf',
    heroImg,
    benefits: benefitsData.map(({ title, description }) => ({
      description: (
        <>
          <strong>{title}</strong>
          <br />
          {description}
        </>
      ),
    })),
    steps: [
      {
        img: step1,
        description: 'User securely enrols payment card to app',
      },
      {
        img: step2,
        description: 'User makes a purchase with enrolled payment card',
      },
      {
        img: step3,
        description: 'User is automatically notified of reward points earned',
      },
      {
        img: step4,
        description:
          'Granular payment data is surfaced via Fidel API’s Dashboard in real-time',
      },
    ],
    hasLogos: true,
  };

  return <UseCase {...props} />;
};

const assetsQuery = graphql`
  {
    heroImg: file(relativePath: { eq: "use-cases/rewards.png" }) {
      childImageSharp {
        gatsbyImageData(width: 812, placeholder: NONE, layout: FIXED)
      }
    }
    step1: file(relativePath: { eq: "use-cases/rewards/step1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 321, placeholder: NONE, layout: FIXED)
      }
    }
    step2: file(relativePath: { eq: "use-cases/rewards/step2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 294, placeholder: NONE, layout: FIXED)
      }
    }
    step3: file(relativePath: { eq: "use-cases/rewards/step3.png" }) {
      childImageSharp {
        gatsbyImageData(width: 355, placeholder: NONE, layout: FIXED)
      }
    }
    step4: file(relativePath: { eq: "use-cases/rewards/step4.png" }) {
      childImageSharp {
        gatsbyImageData(width: 736, placeholder: NONE, layout: FIXED)
      }
    }
  }
`;

export default Rewards;
